const root = document.documentElement;

function getContainerDeltaWidth() {
  const windowWidth = window.innerWidth;
  const maxContainerWidth = 1479;

  return windowWidth > maxContainerWidth ?  (windowWidth - maxContainerWidth) / 2 : 0;
}

function setContainerDelta() {
  const delta = getContainerDeltaWidth();
  console.log(delta, window.innerWidth);
  root.style.setProperty('--container-delta-width', delta + "px");
}


window.onresize = () => {
  setContainerDelta();
};

window.onload = () => {
  setContainerDelta();
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});
